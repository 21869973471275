
@import url('https://fonts.googleapis.com/css2?family=Kalnia+Glaze:wght@100..700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playwrite+BE+VLG&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sevillana&display=swap');
body{
  background:black;
  box-sizing: border-box;
  
}
html{
  scroll-behavior: smooth;
}
.Hero_home{
  background:url('../../public/Asstes/Images/Home.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.First_section_Home{
  padding-top:23px;
}
.First_section_Home .row .col-12{
  padding:0;
  margin:0;
}
.First_section_Home h1{
    padding-top:170px;
    text-align: center;
    color:white;
    font-family: "Poppins", sans-serif;
    font-weight:660;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.First_section_Home p{
    text-align: center;
    padding-bottom:10px;
    color:white;
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
  }
  .Secound_hero_section{
    position: relative;
  }
  .Secound_section_hero{
    background: linear-gradient(180deg, rgba(0,0,0,0.23) 15%, rgba(245,131,39,1) 49%);
  }
  .Secound_hero_section .row .col-12{
    padding:0;
    margin:0;
  }
.Social_media_section_images{
  display: flex;
  align-items: center;
  justify-content: center;
}
.Social_media_section_images .Images_home_social{
  padding-bottom:270px;
  position: absolute;
  right:0;
}
.secound-hero-section .home-images img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.Home_button_images_section {
    background: url('../../public/Asstes/Images/Rectangle (2).png');
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    top:180px;
    width: 100%;
    position: absolute;
}
.Home_button_images_section img{
  padding-left:0px;
  padding-top:120px;
}

.Client_section_main{
  padding-bottom: 70px;
  display:flex;justify-content: center;
}
.Client_section{
  padding:0;
  margin:0;
  padding-top:80px;
}
.Client_section .client_ssection_perent{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.Client_section .client_ssection_perent .TExt_section_client{
  width:44%;

}
.Client_section .client_ssection_perent .TExt_section_client h4{
  padding:0;
  margin:0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color:#F58327;
  font-size: 20px;
}
.client_ssection_perent .Right_line{
  background:#F58327;
  height:2px;
  width:100%;
}
.client_ssection_perent .left_line{
  background:#F58327;
  height:2px;
  width:100%;
}
.Client_Brand_section h3{
  padding-top:16px;
  text-align: center;
  color:white;
  font-size:48px;
  font-family: "Poppins", sans-serif;
}
div.highway-slider {
	display:flex;
	justify-content:center;
	width:100%;
  padding-top:40px;
	div.highway-barrier {
		overflow:hidden;
		position:relative;
	}
	ul.highway-lane {
		display:flex;
		height:100%;
		li.highway-car {
			flex:1;
			display:flex;
			justify-content:center;
			align-items:center;
		}
	}
}

@keyframes translatestf {
	0% { transform:translateX(100%);  }
	100% { transform:translateX(-500%);  }
}
#stffull div.highway-barrier {
	ul.highway-lane {
		width:500%;
		li.highway-car {
			animation:translatestf 30s linear infinite;
			h4 { font-size:28px; }
		}
	}
}

@keyframes translateinfinite {
	100% { transform:translateX(calc(-180px * 12));  }
}
#infinite div.highway-barrier {
	box-shadow:0 3px 10px -3px rgba(0,0,0,0.3);
	&::before,
	&::after {
		content: " ";
		position:absolute;
		z-index:9;
    width:150px;
		height:100%;	
	}
	&::before {
		top:0;left:0;
    background: rgb(0,0,0);
    background: linear-gradient(277deg, rgba(0,0,0,0) 45%, rgba(0,0,0,1) 100%);
	}
	&::after {
		top:0;right:0;
    background: rgb(0,0,0);
background: linear-gradient(277deg, rgba(0,0,0,1) 14%, rgba(0,0,0,0) 100%);
	}
	ul.highway-lane {
		width:calc(180px * 24);
		li.highway-car {
			width:150px;
			animation:translateinfinite 25s linear infinite;
			span.fab {
				font-size:65px;
			}
		}
	}
}

.TExt_services_section h4{
  padding:0;
  margin:0;
  padding-top:10px;
  text-align: center;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  color:#F58327;
  font-size:20px;
}
.TExt_services_section h3{
  padding:0;
  margin:0;
  text-align: center;
  padding-top:10px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color:#FFFFFF;
  padding-bottom:50px;
  font-size:48px;
}
.Services_section_Images{
  padding:0;
  margin:0;
  padding-right:20px;
  padding-left:20px;
  padding-top:10px;
  padding-bottom:20px;
}
.Services_section_Images .Link_undeline{
  text-decoration: none;
}
.Services_section_Images .Link_undeline:hover{
  text-decoration: none;
}
.Services_section_Images .Services_images{
  background:#161616;
  border-radius: 16px;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height:300px;

}
.Services_section_Images .Services_images:hover{
  background:url('../../public/Asstes/Images/card\ 1.png');
  background-size:cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  width:100%;
  border:1px solid #6b6b6b;
  transition: 1s;
  text-decoration: none;
}
.Services_section_Images .Services_images h5{
  text-align: center;
  padding-top:20px;
  font-family: "Poppins", sans-serif;
  color:white;
}
.Companay_experances{
  background:url('../../public/Asstes/Images/testimonial.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.Companay_experances{

  padding-bottom:10px;
}
.Timer_section_exprense{
  padding-left:80px;
  padding-right:100px;
  display:flex;
  justify-content: center;
  padding-top:50px;
  height:300px;
}
.Images_first{
  position: absolute;
  left:10%;
}
.Images_secound{
  position: absolute;
  left:26%;
}
.Images_thard{
  position: absolute;
  left:42%;
}
.Images_fourthed{
  position: absolute;
  left:57%;
}
.Images_fivethed{
  position: absolute;
  left:72%;
}
.Button_images_section{
  padding:0;
  margin:0;
}
.Button_images_section .Button_imGES{
  padding-top:440px;
  padding-left:0;
  padding-right:0;
}
.Button_images_section .Button_imGES img{
  width:100%;
  justify-content: center;
  padding-left:10px;
}
.button_last_section{
  display:flex;
  justify-content: space-between;
  padding-top:30px;
}
.button_last_section button{
  background:transparent;
  border: none;
  padding:0;
  margin:0;
}
.Industry_section_main{
  padding-top:85px;
  padding-bottom:100px;
}
.Insdustery_ssection_perent{
  display:flex;
  align-items: center;
}
.Insdustery_ssection_perent .TExt_section_client{
  width:30%;
}
.Insdustery_ssection_perent .TExt_section_client h4{
  padding:0;
  margin:0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color:#F58327;
}
.Insdustery_ssection_perent .Right_line{
  background:#F58327;
  height:2px;
  width:100%;
}
.Insdustery_ssection_perent .left_line{
  background:#F58327;
  height:2px;
  width:100%;
}
.Insdustery_heading{
  text-align: center;
  padding-top:15px;
  color:#FFFFFF;
  font-weight:600;
  font-family: "Poppins", sans-serif;
  padding-bottom:44px;
}
.Insdustery_Images_section{
  height:380px;
  background:url('../../public/Asstes/Images/Services/Group\ 1000006029.png');
  background-size:cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  padding:0;
  margin:0;
}
.Insdustery_Images_section h1{
  padding:0;
  margin:0;
  padding-top:80px;
  font-family: "Poppins", sans-serif;
  color:white;
  font-weight:600;
  text-transform: uppercase;
  font-size:48px;
}
.Insdustery_text_section{
  padding:0;
  margin:0;
  padding-left:20px;
}
.Insdustery_text_section h1{
  text-transform: uppercase;
  font-size:105px;
  padding:0;
  margin:0;
  font-family: "Poppins", sans-serif;
  font-weight:700;
  line-height: 110px;
  padding-top:30px;
  color:#7A4113;
}
.Industry_expertise_section_right{
  background:#F58327;
  margin-top:16px;
  border-radius: 16px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom:20px;
}
.Industry_expertise_section_right h1{
  padding:0;
  margin:0;
  text-align: right;
  padding-right:30px;
  padding-top:30px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size:64px;
  font-weight:600;
  color:white;
}
.Slider_infinite{
  display:flex;
  gap:10px;
  align-items: center;
}
.Dotes{
  margin-bottom:20px;
  font-weight:800;

}
.Marquee_section h2{
  font-family: "Poppins", sans-serif;
  font-weight:600;
}
div.highway-slider {
	display:flex;
	justify-content:center;
	width:100%;
	div.highway-barrier {
		overflow:hidden;
		position:relative;
	}
	ul.highway-lane {
		display:flex;
		height:100%;
		li.highway-car {
			flex:1;
			display:flex;
			justify-content:center;
			align-items:center;
		}
	}
}

@keyframes translatestf {
	0% { transform:translateX(100%);  }
	100% { transform:translateX(-500%);  }
}
#stffull div.highway-barrier {
	ul.highway-lane {
		width:500%;
		li.highway-car {
			animation:translatestf 30s linear infinite;
			h4 { font-size:28px; }
		}
	}
}

@keyframes translateinfinite {
	100% { transform:translateX(calc(-180px * 12));  }
}
#Industery div.highway-barrier {
	&::before,
	&::after {
		content: " ";
		position:absolute;
		z-index:9;
    width:150px;
		height:100%;	
	}
	&::before {
		top:0;left:0;
	}
	&::after {
		top:0;right:0;
	}
	ul.highway-lane {
		width:calc(180px * 24);
		li.highway-car {
			width:150px;
			animation:translateinfinite 25s linear infinite;
			span.fab {
				font-size:65px;
			}
		}
	}
}
.Industery_section_right_images_section{
  margin-top:16px;
  border-radius: 16px;
  padding-right:16px;
  padding-left:0;
  background:#222222;
}
.Images_section{
  background:#222222;
  height:100%;
  border-radius: 16px;
  overflow: hidden;
  padding-bottom:30px;
}
.Industery_section_right_images_section .Images_section h6{
  padding:0;
  margin:0;
  color:white;
  font-family: "Poppins", sans-serif;
  padding-left:25px;
  padding-top: 25px;;
}
.Industery_section_right_images_section .Images_section p{
  padding:0;
  margin:0;
  color:#F9C092;
  font-family: "Poppins", sans-serif;
  padding-top:10px;
  letter-spacing: 1px;
  font-weight:300;
  padding-left:25px;
  padding-top: 10px;
  padding-bottom:10px;
}
.Industery_section_right_images_section .Images_section img{
  width:100%;
}
.Images_review_section{
  padding:0;
  margin:0;
  padding-top:10px;
  /* border:1px solid red; */
  /* box-shadow: rgba(78, 213, 15, 0.841) 0px 20px 30px -10px; */
}
.Images_review_section img{
  width:100%;
  height:100%;
}
.Review_section_main{
  padding-bottom:100px;
  z-index:999;
  background:url('../../public/Asstes/Images/Group\ 1000006050.png');
  background-size:cover;
  background-repeat:no-repeat;
}
.Slider_section_main_section{
  padding-top:0px;
  padding:0;
  margin:0;
  overflow: hidden;
}
.overSlider_main{
  height:100%;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  box-shadow: 
  inset 0px -90px 60px -28px rgb(7, 7, 7), /* Bottom inset shadow */
  inset 0px 90px 60px -28px rgba(0, 0, 0, 0.911); /* Top inset shadow */

}

.Slider_review_section{
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: -9999;
}
.Slider_section_all{
  width:300px;
  padding-top: 0;
  padding-bottom:40px;
  padding-left:50px;
  padding-top: 30px;
}
.Slider_review_section .slider_item_section{
  display:flex;
  justify-content: center;
  padding-bottom:0px;
  z-index: -9999;
}

.Slider_review_section .slider_item_section h3{
  padding:0;
  margin:0;
  padding-top:70px;
  padding-left:190px;
  color:white;
}
.Slider_review_section .slider_item_section p{
  color:white;
  padding-top:15px;
  width:70%;
  margin-left:170px;
  padding-right:10px;
  padding-bottom:0;
}
.Slider_review_section .slider_item_section  .Fa_icon_testimonial{
  padding:0;
  margin:0;
  display:flex;
  align-items: center;
  padding-left:170px;
  gap:2px;
}
.Slider_review_section .slider_item_section .Fa_icon_testimonial p{
  padding:0;
  margin:0;
  padding-left:10px;
  font-size:12px;
}

.Contact_section_main{
  background-size:cover;
  padding-left:0;
  padding-right:0;
  padding-bottom:100px;
  background:url('../../public/Asstes/Images/Group\ 1000006047.png');
  background-size:cover;
}
.Contact_container{

  padding-top:20px;
  padding-bottom:100px;
}
.Contact_section_main .Contact_container .Form_section{
  display:flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  padding-top:20px;
  padding-bottom:30px;
}
.Contact_section_main .Contact_container .Form_section .FirstName, .PhomeNumber, .Message{
  width:100%;
  padding:23px;
  margin-bottom: 16px;
  border-radius: 50px;
  background:#161616;
  border:none;
  outline:none;
  color:white;
}
.Message{
  width:100%;
  height:70px;

}
.Submit_button{
  width:100%;
  padding:23px;
  margin-bottom: 16px;
  border-radius: 50px;
  background:#F58327;
  color:white;
  outline: none;
  border:1px solid #F58327;
}
.Contact_container_tetx{
  display:flex;
  align-items:center;
  justify-content:start;
  left:0;
  padding:0;
  margin:0;
}
.Contact_container_tetx h1{
  font-size:150px;
  padding:0;
  margin:0;
  font-family: "Poppins", sans-serif;
  font-weight:700;
  color:white;
  text-transform: capitalize;
}
.Contact_container_tetx .Span_text{
  color:#F58327;
  position:absolute;
  top:52%;
}
.Contact_container img{
  width:20%;
  margin-top:30px;
  position: absolute;
  top:20%;
  left:40%;
}
.Contact_container h2{
  color:white;
  font-size:32px;
  padding-top:300px;
  position: absolute;
  top:28%;
  left:38%;
}
.Navbar_botton_cloud{
  width:100%;
  display: flex;
  align-items: center;
}

/* About section coding start  */
.Services_secction{
  padding-top:106px;
  padding-bottom: 70px;
}
.Services_section_container_Cloumn{
  padding:0;
  margin:0;
  padding-bottom:20px;;
  position: relative;
}
.Services_section_container_Cloumn img{
  width:100%;
  height:100%;
}
.Text_section{
  position: absolute;
  top:0;
  width:100%;
}
.Text_section h1{
  padding:0;
  margin:0;
  padding-top:130px;
  color:white;
  font-weight:600;
  font-size:40px;
  padding-bottom:15px;
}
.Text_section p{
  color:#FFFFFF;
}

.Services_section_main_Main_pages_all_left .Services_images_video{
  border-radius: 16px;
  color:white;
}
.Services_section_main_Main_pages_all_right{
  padding-left:16px;
  padding-right:0;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  overflow: auto;
  height: 300px;
  scrollbar-width: thin;
  scrollbar-color: #000 #0000;

}


.Services_section_main_Main_pages_all_right .TExt_our_mission_section i{
  color:white;
  background:#222222;
  PADDING:8PX;
  border-radius: 8PX;
}
.Services_section_main_Main_pages_all_right .TExt_our_mission_section .Vision_section{
  gap:15px;
}
.Services_section_main_Main_pages_all_right .TExt_our_mission_section .Vision_section p{
  padding-top:20px;
}


.Services_section_main_Main_pages_all_right .TExt_our_mission_section .Vision_section .Main_icon_section .Images{
  display:flex;
  align-items: center;
  gap:15px;
}
.Services_section_main_Main_pages_all_right .TExt_our_mission_section .Vision_section h5{
  padding:0;
  margin:0;
  color:white;
}
/* About section coding start */
.About_section_main{
  background:url('../../public/Asstes/Images/AboutImages/Banner.png');
  background-size:cover;
  background-repeat: no-repeat;
  padding-top:110px;
  padding-bottom:20px;
}
.About_section_main .About_images_Hero .row .col-12{
  padding:0;
  margin:0;
}

.About_section_main .About_images_Hero img{
  width:100%;
  height:100%;
}
.About_section_right_text{
  padding:0;
  margin:0;
  color:white;
}
.About_section_right_text p{
  padding:0;
  margin:0;

}
.About_section_OueVission_button{
  display:flex;
  justify-content: space-between;
  margin-top:28px;
}
.About_section_OueVission_button .Images_and_vission h5{
  padding:0;
  margin:0;
}
.About_section_OueVission_button .Images_and_vission{
  display:flex;
  align-items: center;
  gap:15px;
  background:#161616;
  padding:13px;
  padding-right:40px;
  border-radius: 8px;
  color:white;
}

/* About section coding End */
/* Value section main */
.Value_section{
  padding-top:30px;
  padding-bottom:30px;

}
.Value_section .row .col-12{
  padding:0;
  margin:0;
}

/* Our team section */
.Our_team_section_main{
  padding-top:50px;
  padding-bottom:50px;
}
.Our_team_section_main .team_images {
  padding: 0;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  height: 585px;
  transition: transform 0.6s ease;
}
.Slider_first_all:hover {
  animation-play-state: paused;
}

.Images_team {
  filter: saturate(0); 
}

.Images_team:hover {
  filter: saturate(1);
}
.Slider_first_all {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  animation: flip 8s infinite; 
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.First_flip_section, .slider_secound {
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
}
.slider_secound {
  transform: rotateY(180deg);
}
.Our_team_section_main .team_images.rotate {
  transform: rotateY(1turn);
}


.Our_team_section_main .team_images .Team_images_section{
  background:#161616;
  padding:16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}
.Our_team_section_main .team_images .Team_images_section .Team_text{
  padding:0;
  margin:0;
  text-align: left;
  color:#F58327;
  letter-spacing: 1px;
  font-size:33px;
}
.Our_team_section_main .team_images .Team_images_section .Team_value{
  text-align: right;
  letter-spacing: 1px;
  position: absolute;
  font-size:33px;
  right:10px;
  top:34px;
  color:white;
}
.Our_team_section_main .team_images .TEam_button{
  background:url('../../public/Asstes/Images/AboutImages/Rectangle\ 176\ \(1\).png');
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color:white;
  padding:43px;
  padding-bottom:10px;
  position: absolute;
  top:420px;
  width:100%;
  left:0;
}
.Our_team_section_main .team_images .TEam_button h5{
  padding:0;
  margin:0;
  padding-top:13px;
  padding-bottom:5px;
}
.Our_team_section_main .team_images .TEam_button h6{
  font-size:16px;
  padding-top:5px;
  padding-bottom: 0;
}



.Our_team_section_main .team_images {
  padding: 0;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  height: 585px;
  transition: transform 0.6s ease;
}
.Our_team_section_main .team_images {
  padding: 0;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  height: 585px;
  transition: transform 0.6s ease;
}

.Slider_first_all_ppppp{
  animation-play-state: paused;
}

.Images_team_ppppp {
  filter: saturate(1); 
}

.Images_team_ppppp:hover {
  filter: saturate(1);
}
.Images_team_ppppp img{
  width:100%;
  object-fit: cover;
  height:496px;
}
.Slider_first_all_ppppp {
  position: relative;
  width: 100%;
  height: 100%;
}

.First_flip_section_ppppp, .slider_secound_ppppp {
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
}

.slider_secound_ppppp {
  transform: rotateY(180deg);
}

.Our_team_section_main .team_images.rotate_ppppp {
  transform: rotateY(1turn);
}

.Our_team_section_main .team_images .Team_images_section_ppppp {
  background: #161616;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}

.Our_team_section_main .team_images .Team_images_section_ppppp .Team_text_ppppp {
  padding: 0;
  margin: 0;
  text-align: left;
  color: #F58327;
  letter-spacing: 1px;
  font-size: 33px;
}

.Our_team_section_main .team_images .Team_images_section_ppppp .Team_value_ppppp {
  text-align: right;
  letter-spacing: 1px;
  position: absolute;
  font-size: 33px;
  right: 10px;
  top: 34px;
  color: white;
}

.Our_team_section_main .team_images .TEam_button_ppppp {
  background: url('../../public/Asstes/Images/AboutImages/Rectangle\ 176\ \(1\).png');
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  padding: 43px;
  padding-bottom: 10px;
  position: absolute;
  top: 420px;
  width: 100%;
  left: 0;
}

.Our_team_section_main .team_images .TEam_button_ppppp h5 {
  padding: 0;
  margin: 0;
  padding-top: 13px;
  padding-bottom: 5px;
}

.Our_team_section_main .team_images .TEam_button_ppppp h6 {
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 0;
}



/* our Team section main */

/* gellary section coding */
.GALLERY_section_main .row{
  display:flex;
  align-items: center;
  flex-wrap: wrap;
}
.Gallery_section_main {
  padding: 0;
  margin: 0;
  padding: 16px;
  transition: flex 0.3s ease, width 0.3s ease;
  height:352px;
}
.animation_main{
  width:100%;
  height:100%;
  border-radius: 16px;
  animation-name: Animation;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  overflow: hidden;
}
.Images_slider{
  height:352px;
  overflow: hidden;
}
.Images_slider img{
  width:100%;
  height:100%;
  object-fit: cover;
}
.Gallery_section_main_small {
  padding: 16px;
  height: 390px;
  border: 1px solid black;
  transition: flex 0.3s ease, width 0.3s ease;
  height: 352px;
}
.Gallery_section_main_small img{
  width:100%;
  height:320px;
  border-radius: 16px;
}
.Gallery_section_main img{
  width:100%;
  height:320px;
  object-fit: cover;
  border-radius: 16px;
}


/* Mobile App section coding start */
.Moblie_Button_section_main{
  height:70px;
  width:500px;
  display:flex;
  gap:40px;
  padding-top:5px;
}
.Moblie_Button_section_main .Navbar_botton_cloud{
  height:48px;
  width:208px;
  border-radius: 30px;
  background:#F58327;
}
.Moblie_Button_section_main .Navbar_botton_cloud img{
  width:23%;
}
.Moblie_Button_section_main .Navbar_botton_cloud button{
  display: flex;
  align-items: center;
  gap:20px;
  padding-left:10px;
  outline:none;
  background: transparent;
  color:white;
  border:none;
  padding-top:5px;
  padding-bottom:5px;
  font-weight:500;
}
.Moblie_Button_section_main .Navbar_botton_cloud button img{
  width:20px;
}
.Mobile_Button_text_section{
  height:48px;
  width:50%
}
.Mobile_Button_text_section button{
 width:208px;
 height:48px;
 border-radius:30px;
 border:1px solid #FFFFFF;
 background:transparent;
 color:white;
 cursor: pointer;
 outline: none;

}
.Mobile_section_Mission{
  padding-bottom:20px;
}
.Mobile_section_Mission .container .row .Technology_section {
  padding:0;
  margin:0;
  padding:16px;
  display:flex;
  justify-content: space-between;
}
.Mobile_section_Mission .container .row .Technology_section div{
  width:330px;
  background:#161616;
  padding:10px;
  height:60px;
  display:flex;
  align-items:center;
  gap:10px;
  border-radius:16px;
  color:white;
  padding-left:20px;
}
.Mobile_section_Mission .container .row .Technology_section div p{
  padding:0;
  margin:0;

}
.Mobile_Information{
  padding-bottom:20px;
  color:white;
}
.Mobile_Information .container .row .Mobile_All_information_section{
padding:0;
  margin:0;
  text-align:justify;
  font-family: "Poppins", sans-serif;
  padding-bottom:50px;
}
.Mobile_Information .container .row .Mobile_All_information_section p{
  padding:0;
  margin:0;
  letter-spacing: 1px;
}
.images_section_mobile_information{
  padding:0;
  margin: 0;
}
.images_section_mobile_information img{
  width:100%;
  height:100%;
}
.TEXT_button_section_main{
  padding-top:50px;
}
.TEXT_button_section_main .Button_section{
  padding:0;
  margin:0;
  display:flex;
  padding-bottom:50px;
}
.TEXT_button_section_main .Button_section .Button_section_left{
  padding:0;
  margin:0;
}

.TEXT_button_section_main .Button_section .Right_section_text{
  padding:0;
  margin:0;
  padding:15px;
}
.slick-arrow {
  background: black;
  display:none;
  border:none;
}
.PortFolio_section_main {
  padding-top:100px;
}
.PortFolio_section_main .PortFlio_section{
  padding:0;
  margin:0;
  height:472px;
}
.PortFolio_section_main .PortFlio_section img{
  width:100%;
  height:100%;
}
.PortFolio_section_all{
  width:430px;
  height:auto;
  padding:10px;
  overflow: hidden;
}
.PortFolio_section_all_main{
  padding:0;
  margin:0;
  padding:13px;

}

.PortFolio_section_all_main {
  position: relative;
  overflow: hidden; 
}

.PortFolio_section_all_main .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  opacity: 0;
  transition: opacity 0.4s ease; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.PortFolio_section_all_main.overlayp .overlay {
  opacity: 1;
}
.overlay i {
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.PortFolio_section_all img {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.4s ease; 
}

.PortFolio_section_all_main.overlayp img {
  filter: blur(1px); 
}

.PortFolio_section_all img{
  border-radius: 8px;

}
.PortFolio_section_text2 {
  position: relative;
  z-index: 2;
}
.PortFolio_section_text2 h1{
  padding-top:10px;
}
.Features_section{
  display: flex;
  padding-right: 50px;
  justify-content: space-between;
}
.Features_section .Features_main ul li{
  color:white;
  padding-top:10px;
}
.PortFolio_section_all_main .PortFolio_section_all{
  padding:0;
  margin:0;
  padding-right:10px;
  padding-bottom:20px;

}
.PortFolio_section_all_main .PortFolio_section_all img{
  width:400px;
  height:100%;
  object-fit: cover;
  border-radius: 20px;
  height:224px;
}
.PortFolio_section_text{
  padding:0;
  margin:0;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.PortFolio_main{
  padding:16px;
  border-radius: 20px;
  background:#161616;
  height:224px;

}
.PortFolio_section_text .PortFolio_secton_top h5{
  padding-top:0px;
}
.PortFolio_section_text .PortFolio_secton_top h5,p{
  color:white;
}
.PortFolio_section_text .PortFolio_secton_top p{
  text-align: left;
}
.PortFolio_section_text .PortFolio_secton_Bottom{
  /* height:160px; */
  display:flex;
  padding-top:20px;
}
.PortFolio_section_text .PortFolio_secton_Bottom .Timer_section{
  height:100px;
  width:100%;
}
.PortFolio_section_text .PortFolio_secton_Bottom .Timer_section h1{
  color:#F58327;
  padding:0;
  margin:0;
  padding-top:20px;
}
.PortFolio_section_text .PortFolio_secton_Bottom .Timer_section p{
  padding:0;
  margin:0;
  font-size:14px;
}
.PortFolio_section_all_main .Button_section{
  padding-top:50px;
  padding-bottom:10px;
}
.PortFolio_section_all_main .Button_section button{
  padding-left:100px;
  padding-right:100px;
  padding-top:15px;
  padding-bottom:15px;
  border-radius: 30px;
  background: transparent;
  border: 1px solid #FFFFFF;
  color:white;
}
/* portfolio Deatilas pages  */
.PortFolio_section_main_Deatils .PortFlio_section_deatils{
  padding:0;
  margin:0;
  padding:10px;

}
.PortFolio_section_main_Deatils .PortFlio_section_deatils img{
  width:100%;
  height:100%;
}
.PortFlio_section_deatilss{
  padding:10px;
  border-radius: 16px;
  height:100%;
  padding:20px;
}
.PortFlio_section_deatilss img{
  width:100%;
  object-fit: cover;
  border-radius: 16px;
}
.portfolio_details{
  background:url('../../public/Asstes/Images/Group\ 1000006047.png');
  background-size:cover;
  background-repeat: no-repeat;
}
.portFolio_details_pages{
  margin-top:30px;
  /* padding-bottom:30px; */
}
.portFolio_details_pages .portFolio_details_pages_pages{
  padding:0;
  margin:0;
}
.Products_section{
  padding-bottom:40px;
}
.portFolio_details_pages p{
  letter-spacing: 1px;
  text-align: justify;
}
.text_bulid_portfolio h1{
  font-size:105px;
  text-transform: uppercase;
  color:#854C1E;
  font-weight: 600;
  line-height: 100px;
  padding-left:20px;
  padding-top:20px;
}
.Button_section_main_portfolio{
  width:100%;
  padding: 22px;
  display:flex;
  justify-content: space-between  ;
}
.Button_section_main_portfolio button{
  width:340px;
  height:66px;
  border-radius:16px;
  border:none;
  background:#222222;
  text-align: left;
  padding-left: 20px;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 500;
  color:white;
  outline:none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap:0px;
}
.Slider_portfolio_pages{
  height:550px;
  padding:0;
  margin:0;
  padding-left:20px;
}
.slider_portfolio_section{
  background:#161616;
  height:650px;
  border-radius: 16px;
  display:flex;
  padding-left:85px;
  overflow: hidden;
}
.overflow_section_portflio{
  border-radius: 16px;
  height:650px;
  width:95%;
  left:29px;
  right:30px;
  position: absolute;
  box-shadow: 
  rgba(27, 27, 27, 0.938) 0px 100px 36px -28px inset, /* Bottom shadow */
  rgba(27, 27, 27, 0.938) 0px -100px 36px -28px inset; 
}
.slider_portfolio_bttom{
  padding-bottom:30px;
  background:#161616;
  border-radius: 16px;
}
.slider_portfolio_bttom .col-12{
  padding:0;
  margin:0;
}
.slider_portfolio_bttom .col-12 p{
  padding:0;
  margin:0;
  width:52%;
  text-align: justify;
  letter-spacing: 1px;
  padding-left:20px;
  padding-top:20px;
}
.Heading_portfolio{
  width:100%;
  bottom:0;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  color:white;
  border-bottom: 2px solid #F58327;
  width:35%;
}   


/* Product section coding */
.Product_section_main{
  background:url('../../public/Asstes/Images/Group\ 1000006047.png');
  background-size:cover;
  background-repeat: no-repeat;
  padding-top: 70px;
  padding-bottom: 80px;
}
.Product_section_text{
  padding:0;
  margin:0;
  text-align: left;
  letter-spacing: 1px;
}
.Images_text_section{
  padding:0;
  margin:0;
  padding-left:20px;
}
.Product_images{
  background:#161616;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.808) 0px 100px 36px -28px inset;
}
.Product_images img{
  width:100%;
  height:100%;
}
.Product_images_section_main .product_images_sec{
  padding:0;
  margin:0;
}
.Product_images_section_main .product_images_sec img{
  width:100%;
  height:100%;
}
/* product main section coding */

.Product_main_mobile_section_pro{
  padding-top:50px;
  
}
.Mobile_section_product{
  padding:0;
  margin: 0;
  height:550px;
  display:flex;
  justify-content: center;
  align-items: center;
  
  
}
.Mobile_section_product .Product_sec{
  background:#161616;
  height:200px;
  width:100%;
  border-radius: 16px;
  display:flex;
  flex-wrap: wrap;
  align-items: center;

}
.Over_flow_product{
  height:550px;
  width:300px;
  position: absolute;
  top:0px;
  left:20px;
}
.Heading_section h3{
  color:white;
  padding-left:10px;
}
.Heading_section h4{
  padding-left:270px;
  color:white;
}
.Mobile_section_product .Product_sec p{
  width:100%;
  padding-left:270px;
  padding-right:10px;
}
/* get queate section */
.GetQueate_section{
  background:url('../../public/Asstes/Images/Group\ 1000006047.png');
  background-size:cover;
  background-repeat: no-repeat;

}
.OverFlow_GetQuate{
  position: absolute;
  top:80px;
  left: 0;
  height:400px;
  width:100%;
}
.OverFlow_GetQuate img{
  width:40%;
  height:100%;
  object-fit: cover;
}
.GetQueate_section_section_one{
  height:300px;
  margin-top: 100px;
}
/* buuuuu */


/* Privacypolicy_section_maion */

.Privacypolicy_section_maion{
  padding:0;
  margin:0;
  padding-top:150px;
}
.Privacypolicy_section_maion .Privacypolicy_section{
 background:#161616;
 border-radius: 16px;
 padding:20px;
}
.Privacypolicy_section_maion .Privacypolicy_section h1{
  color:white;
}
.Privacypolicy_section_maion .Privacypolicy_section h4{
  color:white;
}

.TERMCONDITION_main{
  padding-top:150px;
}
.TERMCONDITION_section{
  padding:20px;
  background: #222222;
  border-radius: 15px;;
}
.TERMCONDITION_section h4{
  color:white;

}
.Images_section{
  background:transparent;
}
.Images_section img{
  width:100%;
  height: 100%;
}
.Product_btn{
  background:#F58327;
  outline: none;
  border: none;
  width:100%;
  padding:15px;
  border-radius: 30px;
  color:white;
}
.Products_div_buton_section{
  padding:0;
  margin:0;
  padding:15px;
}
.Images_products{
  display:flex;
  justify-content: left;
  align-items: center;
  gap:10px;
  padding:20px;
  border-radius: 13px;
  background:#161616;
}
.Images_products p{
  padding:0;
  margin:0;
}
.Basic_securption{
  background:#161616;
  padding:20px;
  border-radius: 8px;
}
.Suscription_section .Button_click_section h3{
  text-align: center;
  padding-top:10px;
  padding-bottom: 10px;
  color: white;
}
.Suscription_section .Button_click_section .Button_section{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 15px;
  gap:20px;
}
.Suscription_section .Button_click_section .Button_section button{
  padding:10px;
  padding-left:30px;
  padding-right:30px;
  outline: none;
  border:none;
  border-radius: 8px;
  background:#161616;
  color:white;
}
.Suscription_section .Button_click_section .Button_section .active{
  background:#F58327;
  color:white;
  padding:10px;
  padding-left:20px;
  padding-right:20px;
  border-radius: 8px;
}
.Suscription_section .Button_click_section .Button_section p{
  background:#161616;
  padding:10px;
  padding-left:20px;
  padding-right:20px;
  border-radius: 8px;
  cursor: pointer;
}
.Basic_plan_section{
padding: 10px;;
}
.Basic_plan_section .Basic_plan_header h3{
  color:#F58327;
  padding-top:10px;
  padding-left:0px;
  font-size: 15px;
}
.Basic_plan_section .Basic_plan_header h5{
  font-size: 19px;;
  color:white;
  
}
.Basic_plan_section .Basic_plan_header button{
  border:none;
  width:100%;
  height:40px;
  border-radius: 12px;
  outline: none;
  background:#F58327;
  color:white;
}
.Basic_plan_section .Basic_plan_features{
  padding-top:20px;
  color: white;
}
.Basic_plan_section .Basic_plan_features h6{
  padding-bottom: 20px;;
}
.Basic_plan_section .Basic_plan_features .Features_icon{
  display: flex;
  align-items: center;
  gap:7px;
  padding-bottom: 10px;;
}
.Basic_plan_section .Basic_plan_features .Features_icon .fa{
  background:#F58327;
  padding:2px;
  border-radius: 50%;
  padding-left:3px;
  padding-right:3px;
}
.Basic_plan_section .Basic_plan_features .Features_icon p{
  padding:0;
  margin:0;
  font-size: 14px;
}
.Button_section_sec{
  display:flex;
  gap:10px;
}
.Mobile_section .container  h2{
  padding-top:60px;
  text-align: center;
  color:#F58327;
  font-size: 1.5rem;
}
.Mobile_section .container p{
  text-align: center;
  color:#ccc6c6;
  text-transform: capitalize;
}

.Basic_securption_Emm_fearues{
  padding-top:30px;
  padding-bottom:40px;
  background:#222222;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height:350px;
  border-radius: 16px;
}
.Basic_securption_Emm_fearues .Basic_plan_header{
  color:#F58327;
  text-align: center;
}
.Basic_securption_Emm_fearues .Basic_plan_features h4{
  padding-top:0;
  font-size:1rem;
}
.Basic_securption_Emm_fearues .Basic_plan_features p{
  text-align:center;
  letter-spacing: 1px;
  font-size:0.8rem;
  padding:0.8rem;
}
.Basic_securption_Emm_fearues .Basic_plan_features button{
  color:white;
  Border:1px solid #F58327;
  background:transparent;
  width:80%;
  height:50px;
  border-radius:30px;
  outline: none;
  cursor: pointer;
}
.Basic_securption_Emm_fearues .Basic_plan_features button:hover{
  background:#F58327;
  transition:0.5s ease;
}
.Basic_securption_Emm_fearues .Basic_plan_header img{
  font-size: 1.5rem;
  background:#161616;
  padding: 15px;
  border-radius:16px;
}
.EmmMobile_section_coding{
  padding-top:80px;
  padding-bottom: 80px;
 
}
.EmmMobile_section_coding .col-lg-6{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding:0;
  margin:0;
}
.EmmMobile_section_coding .col-lg-6 h1{
  color:#F58327;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 2rem;
}
.EmmMobile_section_coding .images img{
  width:100%;
  height:100%;
  object-fit: cover;
}
.KeyFeatures_asection {
  padding-bottom: 80px;
}
.KeyFeatures_asection .row .col-lg-4{
  padding:10px;
}
.KeyFeatures_asection .row .col-lg-4 .Key_features_items{
  background:#222222;
  height:100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top:40px;
  padding-bottom: 40px;
  padding-left:10px;
  padding-right:10px;
  flex-direction: column;
  text-align: center;
}
.KeyFeatures_asection .row .col-lg-4 .Key_features_items .Icon_features{
  padding:10px;
  width:50px;
  height:50px;
  border-radius: 50%;
  background:#161616;

}
.KeyFeatures_asection .row .col-lg-4 .Key_features_items .Text_features_section p{
  font-size: 0.9rem;
  letter-spacing: 1px;
}
.KeyFeatures_asection .row .col-lg-4 .Key_features_items .Text_features_section h4{
  color:white;
  padding-top:20px;
  padding-bottom:5px;
}
.EmmMobile_section{
    padding-top:100px;
    padding-bottom: 80px;
}
.EmmMobile_imagesover{
  padding:0;
  margin:0;
  padding-top:50px;
}
.button_emm_section{
  display: flex;
  flex-wrap: wrap;
  gap:20px;
  padding-top:1rem;
  padding-bottom: 1rem;
}
.button_emm_section button{
  padding-left:2rem;
  padding-right:2rem;
  padding-top:0.7rem;
  padding-bottom: 0.7rem;
  border:1px solid #f5f5f5;
  border-radius:2rem;
  background:transparent;
  outline: none;
}
.EmmMobile_imagesover h3{
  font-size:2.1rem;
  text-align:left;
  font-weight: 600;
  color: #c5c4c4;
  letter-spacing: 1px;
}
.EmmMobile_imagesover p{
  font-weight: 600;
  color: #c5c4c4;
  letter-spacing: 1px;
}
.EmmMobile_imagesover img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-device-width:1024px){
  .Mobile_all{
    display:none;
  }
  .Software_developent_Desktop{
    display:block;
  }
  .REview_testimonal_mobile{
    display:none;
  }
  .Proudcts_Mobile_section_main{
    display:none;
  }
  .Mobile_Heading{
    display:none;
  }
  .Services_section_main_Main_pages_all_right .TExt_our_mission_section{
    background:#161616;
    padding:25px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
   margin-bottom:22px;
   }
}
@media screen and (max-device-width:1024px){
.ProductS_desktop{
  display:none;
}

 
}
@media screen and (min-device-width: 1025px){
.Proudcts_Mobile_section_main{
  display:none;
}
.Mobile_Heading{
  display:none;
}
.Services_section_main_Main_pages_all_right .TExt_our_mission_section{
  background:#161616;
  padding:25px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
 margin-bottom:22px;
 }
/* About section coding start */
.About_section_main .About_images_Hero .About_text_section{
  padding:0;
  margin:0;
  display: flex;
  justify-content: center;
}
.About_section_main .About_images_Hero .About_text_section h1{
  padding:0;
  margin:0;
  font-size:96px;
  line-height:100px;
  font-weight:600;
  color:white;
  padding-top: 50px;

}
.Services_section_main_Main_pages_all_left{
  padding-left:0;
  padding-right:16px;
  height:300px;
}
.Services_section_main_Main_pages_all_right .TExt_our_mission_section .Vision_section .Main_icon_section{
  display:flex;
  align-items: center;
  width:480px;
  justify-content: space-between;
}
.About_section_main_Main_pages_all_left{
  padding-left:100px;
  padding-top:20px;
}
/* About section coding end */
.Slider_review_section .slider_item_section .Slide_section_child_1{
  background: url('../../public/Asstes/Images/WebDevelopment/Frame\ 165928.png');
  background-size:cover;
  background-repeat:no-repeat;
  width:740px;
  border-radius: 16px;
  height:268px;
  padding-top:20px;
  padding:0;
  padding-right:10px;
  margin:0;
  z-index: -9999;
}
.Slider_review_section .slider_item_section .Slide_section_child_2{
  background: url('../../public/Asstes/Images/WebDevelopment/Frame\ 165929.png');
  background-size:cover;
  background-repeat:no-repeat;
  width:740px;
  border-radius: 16px;
  height:268px;
  padding-top:60px;
  padding-right:10px;
  padding:0;
  margin:0;
  z-index: -9999;
}
.Slider_review_section .slider_item_section .Slide_section_child_3{
  background: url('../../public/Asstes/Images/WebDevelopment/Frame\ 165930.png');
  background-size:cover;
  background-repeat:no-repeat;
  width:740px;
  border-radius: 16px;
  height:268px;
  padding-top:60px;
  padding:0;
  margin:0;
  z-index: -9999;
}
.Slider_review_section .slider_item_section .Slide_section_child_4{
  background: url('../../public/Asstes/Images/WebDevelopment/Frame\ 165931.png');
  background-size:cover;
  background-repeat:no-repeat;
  width:740px;
  border-radius: 16px;
  height:268px;
  padding-top:60px;
  padding:0;
  margin:0;
  z-index: -9999;
}
.Slider_review_section .slider_item_section .Slide_section_child_5{
  background: url('../../public/Asstes/Images/WebDevelopment/Frame\ 165932.png');
  background-size:cover;
  background-repeat:no-repeat;
  width:740px;
  border-radius: 16px;
  height:268px;
  padding-top:60px;
  padding:0;
  margin:0;
  z-index: -9999;
}
.Slider_review_section .slider_item_section .Slide_section_child_6{
  background: url('../../public/Asstes/Images/WebDevelopment/Frame\ 165933.png');
  background-size:cover;
  background-repeat:no-repeat;
  width:740px;
  border-radius: 16px;
  height:268px;
  padding-top:60px;
  padding:0;
  margin:0;
  z-index: -9999;
}
.Slider_review_section .slider_item_section .Slide_section_child_7{
  background: url('../../public/Asstes/Images/WebDevelopment/Frame\ 165928.png');
  background-size:cover;
  background-repeat:no-repeat;
  width:740px;
  border-radius: 16px;
  height:268px;
  padding-top:60px;
  padding:0;
  margin:0;
  z-index: -9999;
}
}
@media screen and (min-device-width: 200px) and (max-device-width: 768px) { 
  body{
    background:black;
    padding-left:15px;
    padding-right:15px;
  }
  .Software_developent_Desktop{
    display:none;
  }
/* Home Section Services section start */
  .Services_section_Images{
    padding:0;
    margin:0;
    padding-right:10px;
    padding-left:10px;
    padding-top:10px;
    padding-bottom:20px;
  }
  .Services_section_main_Main_pages_all_left{
    padding:0;
    margin:0;
    padding-bottom:20px;
    padding-top:20px;
  }
  .Services_section_main_Main_pages_all_left .Services_images_video {
    padding-left:10px;
  }
  .About_section_main_Main_pages_all_left{
    padding-left:0px;
    padding-top:20px;
    padding-right:0;
    display:flex;
    padding-bottom:10px;
    justify-content: center;
  }
/* About section coding start  */

.Services_section_main_Main_pages_all_right .TExt_our_mission_section .Vision_section .Main_icon_section{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.Services_section_main_Main_pages_all_left .Services_images_video{
  text-align: left;
  letter-spacing: 1px;
  padding-left:20px;
}
.About_section_main .About_images_Hero .About_text_section{
  padding:0;
  margin:0;
  display: flex;
  justify-content: center;

}
.About_section_main .About_images_Hero .About_text_section h1{
display:none;

}
.Services_section_main_Main_pages_all_right{
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  overflow: auto;
  height:auto;
  scrollbar-width: thin;
  scrollbar-color: #000 #0000;
}
.Client_section .client_ssection_perent{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.Client_section .client_ssection_perent .TExt_section_client{
  width:100%;
}
.client_ssection_perent .Right_line{
  display:none;
  height:2px;
  width:100%;
}
.client_ssection_perent .left_line{
  background:#F58327;
  height:2px;
  width:100%;
  display:none;
}
.Insdustery_ssection_perent .Right_line{
  background:#F58327;
  height:2px;
  width:100%;
  display: none;;
}
.Insdustery_ssection_perent .left_line{
  background:#F58327;
  height:2px;
  width:100%;
  display: none;
}
.Client_Brand_section h3{
  padding-top:16px;
  color:white;
  text-align: center;
  padding-left:10px;
  padding-right:0;
  letter-spacing: 1px;
  font-size:32px;
  font-family: "Poppins", sans-serif;
}
.TExt_services_section h4{
  padding:0;
  margin:0;
  padding-top:10px;
  text-align: center;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  color:#F58327;
  font-size:20px;
}
.TExt_services_section h3{
  padding:0;
  margin:0;
  text-align: center;
  padding-top:10px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color:#FFFFFF;
  padding-bottom:50px;
  font-size:32px;
  letter-spacing: 1px;
}
.About_section_right_text{
  padding-top:20px;
  margin:0;
  color:white;
}
.About_section_right_text p{
  padding:20px;
  text-align:left;
  letter-spacing: 1px;

}

.About_section_OueVission_button{
  display:flex;
  justify-content: space-between;
  margin-top:28px;
}
.About_section_OueVission_button .Images_and_vission{
  margin:10px;
}
.About_section_OueVission_button .Images_and_vission h5{
  padding:0;
  margin:0;
  font-size: 18px;
}
.About_section_OueVission_button .Images_and_vission{
  display:flex;
  align-items: center;
  gap:15px;
  background:#161616;
  padding:13px;
  padding-right:0px;
  border-radius: 8px;
  color:white;
}
.Value_section{
  padding-top:30px;
  padding-bottom:0;
}
.Value_section .row .col-12 img{
  padding:0;
  margin:0;
  width: 100%;
}
.Gallery_section_main {
  padding: 0;
  margin: 0;
  padding: 16px;
  transition: flex 0.3s ease, width 0.3s ease;
}
.Gallery_section_main img{
  width:100%;
  height:300px;
  object-fit: cover;
}
.Gallery_section_main_small {
  padding: 16px;
  border: 1px solid black;
  transition: flex 0.3s ease, width 0.3s ease;
}
.Gallery_section_main_small img{
  width:100%;
  height:300px;
}
/* About section coding end */
/* services section coding reasponisbe start */
.PortFolio_section_main .PortFlio_section{
  padding:0;
  margin:0;
}
.PortFolio_section_main .PortFlio_section img{
  width:100%;object-fit: cover;
  height:100%;
}
.Insdustery_ssection_perent .TExt_section_client{
  width:100%;
}
/* Portfolio section codingh start  */
.PortFolio_section_main{
  padding-left:10px;
  padding-right:10px;
  overflow: hidden;
}
.PortFolio_section_main .PortFlio_section{
  padding:0;
  margin:0;
}
.PortFolio_section_main .PortFlio_section img{
  width:100%;
  height:100%;
  object-fit: cover;
}
/* portfolio section coding end */

/* Mobile section heading coding start */
.Mobile_Heading{
  color:white;
  text-align: center;
  font-size:25px;
  text-align: left;
  padding-left:10px;
}
.TEXT_button_section_main .Button_section{
  padding:0;
  margin:0;
  display:flex;
  padding-bottom:10px;
}
/* Mobile section section coding end */
/* services section coding responsibe end */

/* Product section responsibe coding start  */
.Features_section{
  display: flex;
  padding-right: 50px;
  justify-content: space-between;
  flex-wrap:wrap;
  letter-spacing: 1px;
}
/* end Product responisbe coding end  */

/* PortFolio details pages responsibe coding start */
.Button_section_main_portfolio{
  width:100%;
  padding: 22px;
  display:flex;
  gap:30px;
  justify-content: space-between  ;
  flex-wrap: wrap;
}
.Slider_portfolio_pages{
  height:500px;
  padding:0;
  margin:0;
  padding-left:0px;
  padding-right:0;
  overflow: hidden;
  background:#222222;
}
.slider_portfolio_section{
  background:#222222;
  height:650px;
  border-radius: 16px;
  display:flex;
  padding-left:20px;
  overflow: hidden;
}
.Slider_review_section{
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: -9999;
}
.Slider_section_all{
  padding-top: 0;
  padding-top: 30px;
}
.Slider_review_section .slider_item_section{
  display:flex;
  justify-content: center;
  padding-bottom:0px;
  z-index: -9999;
}
.overflow_section_portflio{
  border-radius: 16px;
  height:650px;
  width:100%;
  position: absolute;
  left:0;
  box-shadow: 
  rgba(27, 27, 27, 0.938) 0px 100px 36px -28px inset, /* Bottom shadow */
  rgba(27, 27, 27, 0.938) 0px -100px 36px -28px inset; 
}

.text_bulid_portfolio h1{
  text-transform: uppercase;
  color:#854C1E;
  font-weight: 600;
  line-height: 100px;
  padding-left:0px;
  padding-top:20px;
  padding-left:10px;
  padding-right:10px;
  font-size:95px;
}
.Heading_portfolio{
  padding:10px;
  color:white;
  padding-bottom: 0;
  text-align: left;
  letter-spacing: 1px;
  width:100%;
  font-size: 40px;;
}
.Heading_portfolio h1{
  color:whiet;
  font-size: 35px;
}
.portFolio_details_pages p{
  letter-spacing: 1px;
  text-align:left;
  padding:10px;
}
.PortFolio_section_main {
  padding-top:100px;
}
.PortFolio_section_main .PortFlio_section{
  padding:0;
  margin:0;
  height:auto;
}
/* Revew section coding start respnsibe */
.Insdustery_text_section h1{
  text-transform: uppercase;
  font-size: 90px;
  padding:0;
  margin:0;
  font-family: "Poppins", sans-serif;
  font-weight:700;
  line-height: 100px;
  padding-top:30px;
  color:#7A4113;
  text-align: center;
}
.Insdustery_text_section{
  padding:0;
  margin:0;
}
.Insdustery_Images_section{
  padding:0;
  margin:0;
}
.Industery_section_right_images_section{
  padding:0;
  margin:0;
}
.Industry_expertise_section_right{
 padding-left:0;
 padding-right:0;
}

.Slider_review_section .slider_item_section h3{
  padding:0;
  margin:0;
  padding-top:70px;
  padding-left:0px;
  color:white;
}
.Slider_review_section .slider_item_section p{
  color:white;
  padding-top:15px;
  margin-left:0px;
  padding-right:10px;
  padding-bottom:0;
}
.Slider_review_section .slider_item_section  .Fa_icon_testimonial{
  padding:0;
  margin:0;
  display:flex;
  align-items: center;
  padding-left:0px;
  gap:2px;
}
.Slider_review_section .slider_item_section .Fa_icon_testimonial p{
  padding:0;
  margin:0;
  padding-left:10px;
  font-size:12px;
}

/* Revew section coding end responsibe */

/* Exprense section coding start */
.Companay_experances{
  background:url('../../public/Asstes/Images/testimonial.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-left:0;
  display: none;
}
.Companay_experances{

  padding-bottom:10px;
}
.Companay_experances_main img{
  width:22%;
  padding-left:0;
}
.Timer_section_exprense{
  padding-left:0px;
  padding-right:100px;
  display:flex;
  justify-content: center;
  padding-top:50px;
  height:300px;
}
.Images_first{
  position: absolute;
  left:10%;
}
.Images_secound{
  position: absolute;
  left:26%;
}
.Images_thard{
  position: absolute;
  left:42%;
}
.Images_fourthed{
  position: absolute;
  left:57%;
}
.Images_fivethed{
  position: absolute;
  left:72%;
}
.Button_images_section{
  padding:0;
  margin:0;
  display:flex;
}
.Button_images_section .Button_imGES{
  padding-top:0px;
  padding-left:0;
  padding-right:0;
}
.button_last_section{
  display:flex;
  justify-content: space-between;
  padding-top:30px;
}
.button_last_section button{
  background:transparent;
  border: none;
  padding:0;
  margin:0;
  padding:10px;
}
.button_last_section button .Butn_images{
  width:100%;
  height:100%;
}
/* End exprense coding end */
/* Portfolio deatials pages responsibe coding end */
/* Home section coding start */
.First_section_Home{
  padding-top:23px;
}
.First_section_Home .row .col-12{
  padding:0;
  margin:0;
  padding:10px;
}
.First_section_Home h1{
    padding-top:160px;
    text-align: center;
    color:white;
    font-family: "Poppins", sans-serif;
    font-weight:660;
    letter-spacing: 1px;
    font-size:1.4rem;
    text-transform: uppercase;
}
.First_section_Home p{
    text-align: center;
    padding-bottom:10px;
    color:white;
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
  }
  .Moblie_Button_section_main{
    display:flex;
    justify-content: center;
    gap:20px;
    padding-top:5px;
    width:100%;
    padding:10px;
    padding-left:0;
    padding-right:0;
  }
  .Navbar_botton{
    width:100%;
  }
  .Moblie_Button_section_main .Navbar_botton_cloud{
    border-radius: 30px;
    background:#F58327;
    
  }
  .Mobile_Button_text_section{
    height:48px;
    width:100%;
  }
  .Mobile_Button_text_section button{
    width:400px;;
  }
  .Moblie_Button_section_main .Navbar_botton_cloud img{
    width:23%;
  }
  .Secound_hero_section{
    position: relative;
  }
  .Secound_section_hero{
    background: linear-gradient(180deg, rgba(0,0,0,0.23) 15%, rgba(245,131,39,1) 49%);
  }
  .Secound_hero_section .row .col-12{
    padding:0;
    margin:0;
  }
.Social_media_section_images{
  display: flex;
  align-items: center;
  justify-content: center;
}
.Social_media_section_images .Images_home_social{
  padding-bottom:270px;
  position: absolute;
  right:0;
  z-index: -9999;
}
.secound-hero-section .home-images img {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}
.Home_images{
  width:100%;
  height:430px;

}
.Home_button_images_section {
    background: url('../../public/Asstes/Images/Rectangle (2).png');
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    top:180px;
    width: 100%;
    position: absolute;
}
.Home_button_images_section img{
  padding-left:0px;
  width:100%;
  height:100%;
  padding-top:120px;
}
.Insdustery_Images_section{
  background:url('../../public/Asstes/MobileImages/BobileImages.png');
  background-size:cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  padding:0;
  margin:0;
}
.Insdustery_heading{
  padding:0px;
  font-size:32px;
}
.Client_section_main{
  padding-bottom: 0px;
  display:flex;justify-content: center;
}
/* Homne section coding end */
/* Software developemnt section responsibe coding */

.Mobile_section{
  padding:0;
  margin:0;
}
.Text_section{
  position: relative;
  top:0;
  width:100%;
}
.Text_section h1{
  padding:0;
  margin:0;
  padding-top:130px;
  color:white;
  font-weight:600;
  font-size:40px;
  padding-bottom:15px;
}
.Text_section p{
  color:#FFFFFF;
}
.Teachnoloy_section_main{
  display:flex;
  justify-content: center;
  gap:0;
}
.Mobile_section_Mission .container .row .Technology_section {
  padding:0;
  margin:0;
  padding:10px;
}
.Mobile_section_Mission .container .row .Technology_section div{
  background:#161616;
  padding:20px;
  flex-wrap: wrap;
  justify-content:start;
  height: auto;
  display:flex;
  align-items:center;
  gap:10px;
  border-radius:16px;
  color:white;
  padding-left:20px;
}
.Mobile_section_Mission .container .row .Technology_section div img{
  width:40px;
}
.Mobile_section_Mission .container .row .Technology_section div p{
  padding:0;
  margin:0;
text-align: center;
}
.Mobile_Information .container .row .Mobile_All_information_section{
  padding:10px;
  text-align: center;
}
.Button_section_left{
  display:none;
}
.Right_section_text{
  padding:0;
  margin: 0;
}
.Right_section_text p{
  padding-bottom:0;
  padding-top:0;
  text-align: left;
  padding-left: 10px;
}

.Mobile_button_section img{
  width:140px;
  padding:10px;
}
@media screen and (min-device-width:380px){
  .Mobile_button_section img{
    width:170px;
    padding:10px;
  }
}
.Contact_container_tetx h1{
  font-size:140px;
  padding:0;
  margin:0;
  font-family: "Poppins", sans-serif;
  font-weight:700;
  color:white;
  text-transform: capitalize;
}
/* Software developemnt section responsibe coding end */

/* thanku section */
.Contact_container h2{
  color:white;
  font-size:32px;
  padding-top:100px;
  position: absolute;
  top:28%;
  left:0%;
}
.GetQueate_section_section_one{
  height:80px;
}
/* end Thanku section */



/* Products_section coding start */

.Proudcts_Mobile_section_main .Products_section{
  padding:0;
  margin:0;
  display:flex;
  justify-content: center;
}
.Proudcts_Mobile_section_main .Products_section img{
  width:40%;
  height:100%;
}
.Proudcts_Mobile_section_main .Products_section p{
  padding:10px;
  padding-top:20px;
  text-align: left;
}
.Product_section_text{
  padding:10px;
  text-align: left;
  letter-spacing: 1px;
}
/* End Products section coding */


.PortFolio_section_all_main .PortFolio_section_all img{
  width:100%;
  height:100%;
  padding-top:10px;
  object-fit: cover;
  border-radius: 20px;
  height:224px;
}
.PortFlio_section_deatilssss img{
  width: 100%;
  height:100%;;
}
.text_bulid_portfolio h1{
  font-size:90px;
  text-transform: uppercase;
  color:#854C1E;
  font-weight: 600;
  line-height: 100px;
  padding-left:0px;
  padding-top:20px;
  text-align: center;
}
.Button_section_main_portfolio{
  display: flex;
  justify-content: center;
}
.Button_section_main_portfolio button{
  width:280px;
  height:auto;
  border-radius:16px;
  border:none;
  background:#222222;
  text-align: left;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 500;
  color:white;
  outline:none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap:0px;
}
.Moblie_Button_section_main .Navbar_botton_cloud{
  height:48px;
  width:auto;
  border-radius: 30px;
  background:#F58327;
  padding-right:20px;
}
.Moblie_Button_section_main{
  height:70px;
  width:auto;
  display:flex;
  gap:40px;
  padding-top:5px;
}

.Moblie_Button_section_main .Navbar_botton_cloud img{
  width:23%;
}
.Moblie_Button_section_main .Navbar_botton_cloud button{
  display: flex;
  align-items: center;
  gap:20px;
  padding-left:10px;
  outline:none;
  background: transparent;
  color:white;
  border:none;
  padding-top:5px;
  padding-bottom:5px;
  font-weight:500;
}
.Moblie_Button_section_main .Navbar_botton_cloud button img{
  width:20px;
}
.Mobile_Button_text_section{
  height:48px;
  width:60%;
}
.Mobile_Button_text_section button{
 width:auto;
 height:48px;
 border-radius:30px;
 border:1px solid #FFFFFF;
 background:transparent;
 color:white;
 cursor: pointer;
 outline: none;
 padding-left:20px;
 padding-right:20px;

}
.Services_section_main_Main_pages_all_right .TExt_our_mission_section{
  padding:25px;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
 margin-bottom:22px;
 }
  /* Footer section contact section */
  .GetQueate_section_section img{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  .OverFlow_GetQuate{
    position: absolute;
    top:50px;
    left: 0;
    height:100px;
    width:100%;
  }
  .OverFlow_GetQuate img{
    width:40%;
    height:100%;
    object-fit: cover;
  }
  .Contact_container{
    padding-top:20px;
    padding-bottom:0;
  }

  .Contact_section_main .Contact_container .Form_section{
  display:flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  padding-top:20px;
  padding-bottom:30px;
}
.Contact_section_main .Contact_container .Form_section .FirstName, .PhomeNumber, .Message{
  width:100%;
  padding:23px;
  margin-bottom: 16px;
  border-radius: 50px;
  background:#161616;
  border:none;
  outline:none;
  color:white;
}
.Message{
  width:100%;


}

.Contact_container_tetx{
  display:flex;
  align-items:center;
  justify-content:start;
  left:0;
  padding:10px;
  margin:0;
  height:390px;
}
.EmmMobile_imagesover h3{
  font-size:2rem;
  text-align:left;
  font-weight: 600;
  color: #c5c4c4;
  letter-spacing: 1px;
}
.EmmMobile_imagesover p{
  font-weight: 300;
  color: #c5c4c4;
  letter-spacing: 1px;
}
.button_emm_section button{
  padding-left:2rem;
  padding-right:2rem;
  padding-top:0.7rem;
  padding-bottom: 0.7rem;
  border:none;
  border-radius:2rem;
  width:100%;
  background: transparent;
  border:1px solid white;
  outline: none;
} 
.EmmMobile_section_coding .col-lg-6 h1{
  color:#F58327;
  letter-spacing: 2px;
  font-weight: 700;
  font-size:1.7rem;
  text-align: center;
}
.EmmMobile_section_coding .col-lg-6 p{
  text-align: center;
}

























.section-center {
  margin: 0 auto;
  margin-top: 1rem;
  height: 360px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

.person-img {
  margin-bottom: 1rem;
  width:70px;
}

h4 {
  text-transform: uppercase;
  color: #F58327;
  margin-bottom:0;
}
.text {
  margin: 0 auto;
  line-height: 2;
  color: white;
}

.prev,
.next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: #F58327;
  color: white;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: transparent;
  font-size: 1.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}

.prev:hover,
.next:hover {
  background:#F58327;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
  transform: translateX(100%);
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

.Review_section_main_desktop{
  display:none;
}







}













