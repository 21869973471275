@import url('https://fonts.googleapis.com/css2?family=Kalnia+Glaze:wght@100..700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playwrite+BE+VLG&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sevillana&display=swap');
:root
{
  --background:black;
  --card: #F58327;
}
.Navbar_section_main{
  height:100px;
  position: fixed;
  top:0;
  left:0;
  width:100%;
  z-index: 999;
  background:rgba(0,0,0,0.4);
}
.affix{
  background: rgba(0, 0, 0, 0.26);
  backdrop-filter: blur(20px);
}
.nav_container{
  padding-top:16px;
  display:flex;
  align-items: center;
  gap:30px;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  padding-bottom: 20px;
  z-index: 999;
}
.nav_container .Navbar_items{
 border:1px solid #FFFFFF;
 display: flex;
 border-radius:40px;
 padding:10px;
 background:black;
}
.nav_container .Navbar_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav_container .Navbar_items ul li {
  float: left;
  position: relative;
}
.nav_container .Navbar_items ul li a,nav ul li a:visited {
  display: block;
  padding: 0 19px;
  line-height: 40px;
  color: #fff;
  background: #262626 ;
  text-decoration: none;
}
.nav_container .Navbar_items ul li a{
  background: transparent;
}
.nav_container .Navbar_items ul li a:hover, nav ul li a:visited:hover {
  background: #191919;
  color: #F58327;
  border-radius: 30px;
  padding-left:20px;
  padding-right:20px;
}
.navbar-dropdown li a{
  background: #2581DC;
}
.nav_container .Navbar_items ul li a:not(:only-child):after, nav ul li a:visited:not(:only-child):after {
  padding-left: 4px;
  content: ' \025BE';
}
.nav_container .Navbar_items ul li ul li {
  min-width: 190px;
}
.nav_container .Navbar_items ul li ul li a {
  padding: 15px;
  line-height: 20px;
}
.Navbar_botton{
  background:#F58327;
  display: flex;
  border-radius: 30px;
  padding-right:20px;
  padding-top:3px;
  padding-bottom: 3px;
  height:48px;
  width:208px;
  cursor: pointer;
}
.Navbar_botton button{
  border-radius: 30px;
  background:#F58327;
  padding-left:0;
  border:none;
  padding-top:10px;
  padding-bottom:10px;
  display: flex;
  align-items: center;
  padding-left:10px;
  padding-right:10px;
  color:white;
  font-weight:500;
  outline: none;
  cursor: pointer;
}
.Navbar_botton button{
  display:flex;
 justify-content: space-between;
 gap:20px;
}
/* Mobile navigation */
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  height: 55px;
  width: 70px;
} .nav_container .Navbar_items ul li.active a {
  background: #191919;
  color: #F58327;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width:768px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .navbar{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 9999;
  }
  .nav-list{
    list-style: none;
    padding-bottom: 20px;
    background:#161616;
    padding-top:0;
    border-radius: 10px;
  }
  .nav-list li{
    text-align: left;
    padding-top:10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }
.navbar .Mobile_navbar{
  padding-top:30px;
  padding-bottom:10px;
  padding-left:0;
  padding-right:0;
}
  .nav-elements {
    position: absolute;
    right: 0;
    top: 80px;
    width: 0px;
    height: calc(100vh);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 99999;
    background:#161616;
    border-radius: 10px;
  }
  .nav-list li.active a {
    border-radius: 30px;
    background: #0A0A0A;
    padding:10px;
    padding-left:50px;
    padding-right:50px;
    color:#F58327;
    font-weight: 700;
  }
.menu-icon{
 padding:10px;
  border-radius: 8px;
  background:#F58327;
 
  
}
.menu-icon i{
  color:#0A0A0A;
  font-size:20px;
  padding-top:2px;
  padding-bottom: 2px;
  padding-left:7px;
  padding-right:7px;
}
  .nav-elements.active {
    width:100%;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .Navbar_botton button{
    border-radius: 30px;
    background:#F58327;
    padding-left:0;
    border:none;
    padding-top:10px;
    padding-bottom:10px;
    display: flex;
    align-items: center;
    padding-left:10px;
    padding-right:10px;
    color:white;
    font-weight:500;
    outline: none;
    cursor: pointer;
  }
  .Navbar_botton button{
    display:flex;
   justify-content: space-between;
   gap:10px;
  }
}


















.Footer_main_section{
  position: relative;
  height:340px;
}
.Footer_main_section .Footer_section{
padding-left:30px;
padding-right:30px;
}
.Footer_main_section .Footer_section .row{
  background: #0A0A0A;
  border-radius: 32px;
}
.Footer_card_section{
  padding:0;
  margin:0;
  padding:30px;
}
.Card_main{
  background:#161616;
  border-radius: 40px;
  display:flex;
  align-items: center;
  padding:15px;
  width:100%;
  gap:12px;
  height:80px;
  margin-bottom:40px;
}
.Card_main div p{
  padding:0;
  margin:0;
  font-weight:200;
 color:white;
}
.QuckLink_section_footer .QuckLink_section_footer_first h5{
  padding:0;
  margin:0;
  padding-top:25px;
  font-family: "Poppins", sans-serif;
  font-weight:600;
  color:#F58327;
  padding-bottom:13px;
}
.QuckLink_section_footer .QuckLink_section_footer_first ul{
  list-style: none;
  padding:0;
  margin:0;
}
.QuckLink_section_footer .QuckLink_section_footer_first ul li{
  font-weight:200;
  line-height: 28px;
  color:white;
}
.QuckLink_section_footer  .QuckLink_section_footer_secound{
  padding-left:44px;
}
.QuckLink_section_footer  .QuckLink_section_footer_secound h5{
  padding:0;
  margin:0;
  padding-top:25px;
  font-family: "Poppins", sans-serif;
  font-weight:600;
  color:#F58327;
  padding-bottom: 15px;
}
.QuckLink_section_footer  .QuckLink_section_footer_secound ul{
  list-style: none;
  padding:0;
  margin:0;
}
.QuckLink_section_footer  .QuckLink_section_footer_secound ul li{
 font-size:16px;
 color:white;
 font-weight:200;
 line-height: 28px;
}
.QuckLink_section_footer .QuckLink_section_footer_thard h5{
  padding:0;
  margin:0;
  padding-top:25px;
  font-family: "Poppins", sans-serif;
  font-weight:600;
  color:#F58327;
  padding-bottom:15px;
}
.QuckLink_section_footer .QuckLink_section_footer_thard ul{
  list-style: none;
  padding:0;
  margin:0;
}
.QuckLink_section_footer .QuckLink_section_footer_thard ul li{
 font-weight:200;
 line-height: 28px;
 color:white;
}
.Footer_images_flow_section{
  padding-top:80px;
  display:flex;
  justify-content: space-between;
  width:100%;
  padding-right:10px;
}
.Footer_images_flow_section .Footer_images{
  display:flex;
  gap:20px;
  flex-wrap: wrap;
}
.Footer_images_flow_section .Footer_images img{
  border-radius: 8px;
}
.Copy_right_section{
  background:black;
  padding-bottom:30px;
  position: absolute;
  left:0;
  top:100%;
  width:100%;
  z-index: -999;
}
.Copy_right{
  background:#F58327;
  height:200px;
  border-radius: 32px;
}
.Copy_right p{
  padding-top:146px;
  padding-left:20px;
  font-size:16px;
  font-weight:600;
}




@media screen and (min-width:1027px){
  /* Navbar section mobile  */
  .navbar{
    display:none;
  }
  .Mobile_navbar{
    display: none;
  }
}
@media screen and (min-device-width: 290px) and (max-device-width: 768px) { 
  .Navbar_section_main{
    display:none;
  }
 
  /* Footer section coding start */

  .Footer_main_section{
    position: relative;
    height:1230px;
    width:100%;

  }
  .Footer_main_section .Footer_section{
  padding-left:0px;
  padding-right:0px;
  }
  .Footer_main_section .Footer_section .row{
    border-radius: 32px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:20px;
  }
  .Footer_card_section{
    padding:0;
    margin:0;
    padding:10px;
  }
  #MobileMessages{
    height:200px;
    border-radius:12px;
  }
  .Card_main{
    background:#161616;
    border-radius: 40px;
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    padding:15px;
    width:100%;
    gap:5px;
    margin-bottom:40px;
  }
  .Card_main .Number{
   display: flex;
   gap:10px;
   color:red;
  }
  #Card_Messages{
    height:200px;
    border-radius: 12px;
  }
  .QuckLink_section_footer  .QuckLink_section_footer_secound{
    padding-left:10px;
  }
  .Copy_right p{
    padding-top:130px;
    padding-left:20px;
    font-size:16px;
    font-weight:600;
    font-size:15px;
  }
  }
  




  